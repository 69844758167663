var cookies = {
    set: function (cname, value, exdays) {
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + exdays);
        var cvalue = value + ((exdays === null) ? "" : "; expires=" + exdate.toUTCString());
        var cookie = cname + "=" + cvalue + "; path=/";
        if (document.location.protocol === 'https:') {
            cookie = cname + "=" + cvalue + "; secure; path=/";
        }
        document.cookie = cookie;
    },
    get: function (cname) {
        var i, x, y, ARRcookies = document.cookie.split(";");
        for (i = 0; i < ARRcookies.length; i++) {
            x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
            y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
            x = x.replace(/^\s+|\s+$/g, "");
            if (x === cname) {
                return y;
            }
        }
    },
    remove: function (cname) {
        if (document.cookie !== document.cookie) {
            index = document.cookie.indexOf(cname);
        } else {
            index = -1;
        }
        if (index === -1) {
            document.cookie = cname + "=GONEcbEndCookie; expires=Monday, 19-Aug-1996 05:00:00 GMT; path=/";
        }
    }
};

window.site = {
    uptoXLarge: function () { return ($(window).width() <= 1499) },
    overXLarge: function () { return ($(window).width() >= 1500) },
    uptoLarge: function () { return ($(window).width() <= 1199) },
    overLarge: function () { return ($(window).width() >= 1200) },
    uptoMedium: function () { return ($(window).width() <= 991) },
    overMedium: function () { return ($(window).width() >= 992) },
    uptoSmall: function () { return ($(window).width() <= 767) },
    overSmall: function () { return ($(window).width() >= 768) },
    uptoXSmall: function () { return ($(window).width() <= 479) },
    overXSmall: function () { return ($(window).width() >= 480) }
};

var popup = $.magnificPopup;

$(document).on('click', '.vt-cookie',
    function (e) {
        e.preventDefault();
        var value = $(this).data("vt");
        cookies.set("vt", value, 30);
        if (value === "Vans") {
            var cookieVan = cookies.get("compVan");
            if (cookieVan !== undefined) {
                var lengthVan = cookieVan.replace(",,", ",").replace(/^,+/, "").replace(/,+$/, "").split(',').filter(Boolean).length;
                if (lengthVan > 0) {
                    $('#header .comparison-count').html(lengthVan);
                } else {
                    $('#header .comparison-count').html("");
                }
                if (lengthVan === 1) {
                    $('#compareLink').text("My Showroom");
                } else {
                    $('#compareLink').text("My Showroom");
                }
            } else {
                $('#header .comparison-count').html("");
                $('#compareLink').text("My Showroom");
            }
        }
        else if (value === "Cars") {
            var cookieCar = cookies.get("compCar");
            if (cookieCar !== undefined) {
                var lengthCar = cookieCar.replace(",,", ",").replace(/^,+/, "").replace(/,+$/, "").split(',').filter(Boolean).length;
                if (lengthCar > 0) {
                    $('#header .comparison-count').html(lengthCar);
                } else {
                    $('#header .comparison-count').html("");
                }
                if (lengthCar === 1) {
                    $('#compareLink').text("My Showroom");
                } else {
                    $('#compareLink').text("My Showroom");
                }
            } else {
                $('#header .comparison-count').html("");
                $('#compareLink').text("My Showroom");
            }
        };
    });

$(document).on('click', '.cars-tab-filter',
    function (e) {
        e.preventDefault();
        $('input[name="IsCar"]').val('true');
        $('.cars-tab-filter').addClass('active');
        $('.vans-tab-filter').removeClass('active');
        var searchForm = $(".vehicle-search__form");

        $.post(searchForm.data("filterroute"), searchForm.serialize(), function (response) {
            var umbRouting = searchForm.find("input[name=ufprt]").clone();
            searchForm.html(response).append(umbRouting);
            searchForm.find('.selectpicker').selectpicker('refresh');
        });

    });

$(document).on('click', '.vans-tab-filter',
    function (e) {
        e.preventDefault();
        $('input[name="IsCar"]').val('false');
        $('.vans-tab-filter').addClass('active');
        $('.cars-tab-filter').removeClass('active');
        var searchForm = $(".vehicle-search__form");

        $.post(searchForm.data("filterroute"), searchForm.serialize(), function (response) {
            var umbRouting = searchForm.find("input[name=ufprt]").clone();
            searchForm.html(response).append(umbRouting);
            searchForm.find('.selectpicker').selectpicker('refresh');
        });
    });

$(document).on('click', '.pt-cookie',
    function (e) {
        e.preventDefault();
        cookies.set("pt", $(this).data("pt"), 30);
    });


$(document).on('click', '.faqs .question',
    function () {
        console.log('clicked faq');
        $(this).parent().toggleClass('open');
    });

function hasFormValidation() {
    return (typeof document.createElement('input').checkValidity === 'function');
};

//Use HTML% api to see if a form field is valid
$.fn.isValid = function () {
    if (hasFormValidation())
        return this[0].checkValidity();
};

window.site.trackEvent = function (category, action, label, value) {
    ga('send', 'event', category, action, label, value || 0);
};

window.site.QueryStringToJSON = function (query) {
    var pairs = (query)
        ? query.split('&')
        : location.search.slice(1).split('&');

    var result = {};
    pairs.forEach(function (pair) {
        pair = pair.split('=');
        result[pair[0]] = decodeURIComponent(pair[1] || '');
    });

    return JSON.parse(JSON.stringify(result));
};

function getUrlParameter(sParam) {
    var sPageUrl = window.location.search.substring(1);
    var sUrlVariables = sPageUrl.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sUrlVariables.length; i++) {
        sParameterName = sUrlVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
};

function getQuoteSource() {
    if (document.location.search.length) {
        console.log(window.location.search);       

        if (window.location.search.indexOf('?gclid=') !== -1) {
            cookies.set("ref", "2", 7);
            cookies.set("sref", getUrlParameter("gclid"), 7);
        }
        else if (window.location.search.indexOf('?msclkid=') !== -1) {
            cookies.set("ref", "5", 7);
        }
        else if (window.location.search.indexOf('?utm_source=email') !== -1 || window.location.search.indexOf('utm_medium=email') !== -1) {
            cookies.set("ref", "3", 7);
        }
        else if (window.location.search.indexOf('?utm_source=facebook') !== -1) {
            cookies.set("ref", "4", 7);
        }
        else if (window.location.search.indexOf('?utm_source=display') !== -1) {
            cookies.set("ref", "6", 7);
        }
        else if (window.location.search.indexOf('?utm_source=text') !== -1) {
            cookies.set("ref", "7", 7);
        }
        else {
            cookies.set("ref", "1", 7);
        }
        if (window.location.search.indexOf('?utm_campaign=') !== -1) {
            cookies.set("sref", getUrlParameter("utm_campaign"), 30);
        }
    } else if (cookies.get('ref') === undefined){
        cookies.set("ref", "1", 7);
    }
}

function getScreenSize() {
    if (cookies.get('scrnhght') === undefined || cookies.get('scrnhght') === '0') {
        var height = $(window).height();
        cookies.set("scrnhght", height, 7);
    }
    if (cookies.get('scrnwdth') === undefined || cookies.get('scrnwdth') === '0') {
        var width = $(window).width();
        cookies.set("scrnwdth", width, 7);
    }
}

function placeholderFallback() {
    if (!Modernizr.placeholder) {
        $('[placeholder]').focus(function () {
            var input = $(this);
            if (input.val() === input.attr('placeholder')) {
                input.val('');
                input.removeClass('placeholder');
            }
        }).blur(function () {
            var input = $(this);
            if (input.val() === '' || input.val() === input.attr('placeholder')) {
                input.addClass('placeholder');
                input.val(input.attr('placeholder'));
            }
        }).blur();
        $('[placeholder]').parents('form').submit(function () {
            $(this).find('[placeholder]').each(function () {
                var input = $(this);
                if (input.val() === input.attr('placeholder')) {
                    input.val('');
                }
            })
        });
    }
}

function validFormField($field) {

    if ($field.attr('required') || $field.attr('required') === "required") {
        if ($field.isValid())
            $field.parents('.form-group').addClass('valid');
        else
            $field.parents('.form-group').removeClass('valid');
    } else {
        if ($field.val().length > 0)
            $field.parents('.form-group').addClass('valid');
        else
            $field.parents('.form-group').removeClass('valid');
    }
}

(function (document, window, undefined) {

    if (cookies.get('vt') === undefined) {
        cookies.set("vt", "Cars", 30);
    }

    placeholderFallback();
    getQuoteSource();
    slickInit();
    getScreenSize();

    $(document).on('click', '.js-toggle-filter a', function (e) {
        window.site.trackEvent('Pricing', 'click', 'Pricing ' + $(this).text());
    });

    //Check form fields to see if they're valid on page load
    $('.js-validate-check').each(function () {
        validFormField($(this));
    });

    //Check if form fields are valid on keyup
    $(document).on('blur', '.js-validate-check', function () {
        validFormField($(this));
    });

    /* ============================================================================================================================================= */
    /* == STOP - This line is because chrome can't select the correct selected option, it might be fixed by the time you read this so don't judge == */
    /* ============================================================================================================================================= */
    $('select[name="vehicle-make"]').val($('select[name="vehicle-make"] .selected').val());


    $(window).on('updateheights', function (event, originalEvent) {
        $('.fullscreen')
            .css('height', 'auto')
            .css('height', $('body')[0].scrollHeight);

        $('.windowheight')
            .css('min-height', 'auto')
            .css('min-height', $('body').height());  
    });

    // Trigger updateheights on document ready
    $(document).ready(function (e) {

        $(this).trigger('updateheights', 'documentready');
        /// Trigger a click on load
        $('.js-trigger-click-on-load').trigger('click');

        $(document).on('click', '.js-scroll-to-link', function (e) {
            e.preventDefault();
            $element = $('[name="' + $(this).attr('href').replace('#', '') + '"]');

            $("html, body").animate({
                scrollTop: $element.offset().top - 100
            }, 600);
        });

    });

    if ($('.primary-filter-bar-fixed').length) {
        var $fixedFilterBar = $('.primary-filter-bar-fixed'),
            $filterBar = $('.primary-filter-bar'),
            filterBarPosition = $filterBar.offset().top;

        $(window).on('scroll', function () {
            if (Modernizr.mq('(min-width: 768px)') && !$('html').hasClass('no-fixed-filter-bar')) {
                if ($(window).scrollTop() >= filterBarPosition)
                    $fixedFilterBar.addClass('visible');
                else
                    $fixedFilterBar.removeClass('visible');
            }
        });
    }

    //Show secondayr filter bar is user has scroll past it
    if ($('.secondary-filter-bar').length) {
        $(window).on('scroll', function () {
            if ($(window).scrollTop() > $('.secondary-filter-bar').offset().top - $('.primary-filter-bar-fixed ').outerHeight())
                $('.fixed-sceondary-filter-bar').show();
            else
                $('.fixed-sceondary-filter-bar').hide();
        });
    }

    // Set trigger for window events to run thorugh updateheights event
    $(window).on('load resize orientationchange', function (e) {
        $(this).trigger('updateheights', e.type);
    });

    // Plugin attachments

    //Only applies mmobile true if actually on mobile due to bug in selectpicker where it doesn't know difference between desktop and mobile
    if (Modernizr.mq('(max-width: 767px)')) {
        $('.selectpicker').selectpicker({
            mobile: true,
        });
    }
    else {
        $('.selectpicker').selectpicker();
    }

    if ($('.js-carousel-slider').length > 0) {
        $('.js-carousel-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            cssEase: 'linear',
            arrows: true
        });
    }

    if ($('.js-carousel-slider-feefo').length > 0) {
        $('.js-carousel-slider-feefo').slick({
            slidesToShow: 2,
            slidesToScroll: 1,
            cssEase: 'linear',
            arrows: true,
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }

    function slickInit() {
        $('.js-slick-slider').each(function () {
            var $this = $(this);
            $this.slick({
                autoplay: $this.data('slick-autoplay') || false,
                autoplaySpeed: $this.data('slick-autoplay-speed') || 4000,
                speed: $this.data('slick-speed') || 800,
                slidesToShow: $this.data('slick-slides-to-show') || 3,
                slidesToScroll: $this.data('slick-slides-to-scroll') || 1,
                dots: $this.data('slick-dots') || false,
                arrows: $this.data('slick-arrows') === undefined ? true : false,
                responsive: $this.data('slick-responsive') || [
                    {
                        breakpoint: 1199,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            infinite: true,
                            "centerMode": true,
                            "centerPadding": '10px'
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            "centerMode": true,
                            "centerPadding": '10px'
                        }
                    },
                ],
                onBeforeChange: function (slick, curIndex, nextIndex) {
                    $this.trigger('beforeChange.slick', [curIndex, nextIndex]);
                },
                onAfterChange: function () {
                    $this.trigger('afterChange.slick');
                }
            });
        });
    }

    function toggleDesktopFilterOptions($el) {
        if ($el.hasClass('is-active')) {
            $el.next().fadeOut();
            $el.removeClass('is-active');
        } else {
            $el.next().fadeIn();
            $el.addClass('is-active');
        }
    }

    function toggleHeaderPopover($el) {
        var thisPanel = $el.data('trigger-panel');
        $el.closest('.tab-content').find('.icon').removeClass('icon-chevron-up');
        $el.closest('.tab-content,.js-open-mobile-accordion-body').find('.accordian-rows').removeClass('active');
        $('.tab-content .js-accordion-content').slideUp(200);
        $el.next('.icon').addClass('icon-chevron-up');
        if ($('#' + thisPanel).css('display') === 'block') {

            $('body').removeClass('header-popover-active');
            if (window.site.overSmall()) {
                $el.siblings('.icon').removeClass('icon-chevron-up');
                $('#' + thisPanel).fadeOut().siblings('.header-popover').fadeOut();
            }

            else {
                $('#' + thisPanel).slideUp().siblings('.header-popover').slideUp();
                $el.siblings('.icon').toggleClass('icon-chevron-up');

                if (thisPanel !== "header-main-nav-container")
                    $('.header__main-nav-container').slideUp();
            }
            if ($('.header-popover--default')) {
                if (Modernizr.mq('(max-width: 767px)')) {
                    $('.header-popover--default').slideDown();
                } else {
                    $('.header-popover--default').fadeIn();
                }
            }
        }
        else {

            $('body').addClass('header-popover-active');

            if (window.site.overSmall()) {

                $el.closest('.accordian-rows').addClass('active');
                $('#' + thisPanel).fadeIn().siblings('.header-popover').fadeOut();
            }
            else {
                $el.siblings('.icon').addClass('icon-chevron-up');

                $el.closest('.accordian-rows').addClass('active');
                $('#' + thisPanel).slideDown().siblings('.header-popover').slideUp();

                if (thisPanel !== "header-main-nav-container")
                    $('.header__main-nav-container').slideUp();
            }
        }
    };

    $(document).on('click', '.js-toggle-header-popover', function (e) {
      e.preventDefault();
      e.stopPropagation();
      toggleHeaderPopover($(this));

      var self = this;
      setTimeout(function() {
        var theOffset = $(self).offset();
        $('body,html').animate({ scrollTop: theOffset.top - 100 });
      }, 310);
    });


    $(document).on('click', '.header-popover__close', function (e) {
        e.preventDefault();
        e.stopPropagation();
        toggleHeaderPopover($(this));
    });

    $(document).on('click', '.js-toggle-filter-popover', function (e) {
        e.preventDefault();
        e.stopPropagation();
        toggleDesktopFilterOptions($(this));
    });

    $('#mobile-header-trigger, #mobile-header-search-trigger, #mobile-header-social-trigger').on('click', function (e) {
        $("html, body").animate({ scrollTop: 0 });
    });

    var exitModalDisplayed = false;
    document.addEventListener("DOMContentLoaded", function() {
        var exitModalAlreadyDisplay = cookies.get('SynergyExitModal');
        if (exitModalAlreadyDisplay !== undefined && exitModalAlreadyDisplay === "1")
            exitModalDisplayed = true;

        document.addEventListener("mouseout", function(event) {
            if (!event.toElement && !event.relatedTarget && !exitModalDisplayed) {
                exitModalDisplayed = true;
                cookies.set('SynergyExitModal', '1', 1);
                $.magnificPopup.open({
                    type: 'ajax',
                    items: { src: $('.js-mailing-list-submit').attr('action') + '?exitModel=true' },
                    mainClass: "mfp-zoom-out",
                    focus: 'input:first',
                    removalDelay: 500,
                    closeOnBgClick: false,
                    callbacks: {
                        ajaxContentAdded: function () {
                            placeholderFallback();
                            $('.js-validate-check').each(function () {
                                validFormField($(this));
                            });
                        },
                        close: function () {
                            // Destroy the slider to avoid memory leaks
                            var slider = this.content.find('.royalSlider').data('royalSlider');
                            slider && slider.destroy();
                        }
                    }
                });
            }
        });
    });

    $('.js-mailing-list-submit').on('submit', function (e) {
        e.preventDefault();
        var $this = $(this),
            emailAddr = $this.find('input[name=email]').val();

        $this.magnificPopup({
            type: 'ajax',
            items: { src: $(this).attr('action') },
            mainClass: "mfp-zoom-out",
            focus: 'input:first',
            removalDelay: 500,
            closeOnBgClick: false,
            callbacks: {
                parseAjax: function () {
                    $('#form-mailing-list-popup__email').val(emailAddr);
                },
                ajaxContentAdded: function () {
                    $('#form-mailing-list-popup__email').val(emailAddr);
                    placeholderFallback();
                    $('.js-validate-check').each(function () {
                        validFormField($(this));
                    });
                },
                close: function () {
                    // Destroy the slider to avoid memory leaks
                    var slider = this.content.find('.royalSlider').data('royalSlider');
                    slider && slider.destroy();
                }
            }
        }).magnificPopup('open');
    });

    $(document).on('submit', '.form-mailing-list-popup', function (e) {
        e.preventDefault();
        var form = $('.form-mailing-list-popup');
        var submit = form.find('button[type="submit"]');
        var currentText;

        if (submit.length) {
            currentText = submit.text();
            submit.text("Submitting...");
        } else {
            submit = form.find("input[type='submit']");
            currentText = submit.text();
            submit.val("Submitting...");
        }
        submit.attr("disabled", true);
        $.ajax({
            type: "POST",
            url: form.prop('action'),
            data: form.serializeArray(),
            success: function (data) {
                submit.removeAttr("disabled");
                if (submit.is("input")) {
                    submit.val(currentText);
                } else {
                    submit.text(currentText);
                }
                if (data.SaveSuccessful) {
                    form.find('.response-holder').html('<p class="alert alert-success" style="display: block;"><span class="icon_holder"><i class="icon-ok-sign"></i>' + data.SubmissionText + '</span></p>');
                    form.find('.form-group').hide();
                } else {
                    form.find('.response-holder').html('<p class="alert alert-danger" style="display: block;"><span class="icon_holder"><i class="icon-warning-sign"></i> There was an issue submitting your details. Please try again.</span></p>');
                }
            },
            error: function () {
                submit.removeAttr("disabled");
                if (submit.is("input")) {
                    submit.val(currentText);
                } else {
                    submit.text(currentText);
                }
            }
        });
    });

    $(document).on('click', '.js-popup-ajax', function (e) {
        e.preventDefault();

        var popupOptions = {
            type: "ajax",
            mainClass: "mfp-zoom-out",
            focus: 'input:first',
            removalDelay: 500,
            closeOnBgClick: false,
            callbacks: {
                ajaxContentAdded: function () {
                    placeholderFallback();
                }
            }
        };
        popupOptions.items = { src: $(this).attr('href') };
        $.magnificPopup.open(popupOptions);
    });

    $("select#order_by_desktop,select#order_by_mobile").each(function () {

        var $select = $(this),
            $select_default = $select.data('default-text');
        $selected_option = $select.find('option:selected');

        if ($select_default && $selected_option) {
            $selected_option.attr('data-default-text', $selected_option.text());
            $selected_option.text($select_default);
        }
    });

    $("select#order_by_desktop,select#order_by_mobile").focusin(function () {

        var $select = $(this),
            $select_default = $select.data('default-text'),
            $option = $select.find('option:selected');

        if ($select_default) {
            $option.text($option.attr('data-default-text'));
        }
    });

    $(document).on("change", ".vehicle-search__form .js-trigger-select-change", function () {
        var form = $(".vehicle-search__form:visible");
        $.post(form.data("filterroute"), form.serialize(), function (response) {
            var umbRouting = form.find("input[name=ufprt]").clone();
            form.html(response).append(umbRouting);
            form.find('.selectpicker').selectpicker('refresh');
            if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
              $('.selectpicker').selectpicker('mobile');
            }
        });
    });

    $(document).on('click', '.checkbox-circle-checker', function (e) {
        e.preventDefault();
        $(this).toggleClass('checked');
    });

    $(document).on('click', '.js-checkbox-toggle', function (e) {
        e.preventDefault();

        $thisInput = $(this).find('.checkbox-input');
        $thisCheckbox = $(this).find('.pseudo-checkbox');

        if ($thisInput.prop('checked') === true) {
            $thisCheckbox.removeClass('checked');
            $thisInput.prop('checked', false);
        }
        else {
            $thisInput.prop('checked', true);
            $thisCheckbox.addClass('checked');
        }
    });

    $(document).on('change', '.contact-contract-type-js', function (e) {
        if ($(this).val() === "business") {
            $(".show-business-name-js").show("slow");
        }
        else if ($(this).val() === "personal") {
            $(".show-business-name-js").hide("slow");
        }
    });

    $(document).on('click', '.change-compare-status', function (e) {

        var cookiename = "compCar";
        if (cookies.get("vt") === "Vans") {
            cookiename = "compVan";
        }

        e.preventDefault();
        var $this = $(this);
        var capID = $this.attr("data-vehicle-cap-id");
        var option = $this.attr("data-status");
        if (option === 'add') {
            if (cookies.get(cookiename) === undefined) {
                cookies.set(cookiename, capID, 30);
                popup.open({
                    type: 'ajax',
                    items: { src: "/shared-assets/popups/comparison-add/" },
                    mainClass: "mfp-zoom-out",
                    removalDelay: 500,
                    closeOnBgClick: false
                });
                $this.removeClass('add');
                $this.addClass('remove');
                $this.attr('data-status', 'remove');
                $this.find('.js-comparrison-tool-text').html('Remove from My Showroom');
                $this.find('path').attr('fill', '#d75a4a');
                $this.find('input').attr('checked', true);
                var cookieLengthNew = cookies.get(cookiename).replace(",,", ",").replace(/^,+/, "").replace(/,+$/, "").split(',').filter(Boolean).length;

                if (cookieLengthNew > 0) {
                    $('#header .comparison-count').html(cookieLengthNew);
                } else {
                    $('#header .comparison-count').html("");
                }
                if (cookieLengthNew === 1) {
                    $('#compareLink').text("My Showroom");
                } else {
                    $('#compareLink').text("My Showroom");
                }
            }
            else {
                var cookieAddVal = cookies.get(cookiename);
                if (cookieAddVal !== null && cookieAddVal.replace(",,", ",").replace(/^,+/, "").replace(/,+$/, "").split(',').filter(Boolean).length < 4) {
                    popup.open({
                        type: 'ajax',
                        items: { src: "/shared-assets/popups/comparison-add/" },
                        mainClass: "mfp-zoom-out",
                        removalDelay: 500,
                        closeOnBgClick: false
                    });;

                    var newCookie = cookieAddVal.replace(",,", ",").replace(/^,+/, "").replace(/,+$/, "") + "," + capID;
                    cookies.set(cookiename, newCookie, 30);
                    $this.removeClass('add');
                    $this.addClass('remove');
                    $this.attr('data-status', 'remove');
                    $this.find('.js-comparrison-tool-text').html('Remove from My Showroom');
                    $this.find('path').attr('fill', '#d75a4a');
                    $this.find('input').attr('checked', true);

                    var cookieLengthAdd = newCookie.split(',').filter(Boolean).length;

                    if (cookieLengthAdd > 0) {
                        $('#header .comparison-count').html(cookieLengthAdd);
                    } else {
                        $('#header .comparison-count').html("");
                    }
                    if (cookieLengthAdd === 1) {
                        $('#compareLink').text("My Showroom");
                    } else {
                        $('#compareLink').text("My Showroom");
                    }
                }
                else {
                    popup.open({
                        type: 'ajax',
                        items: { src: "/shared-assets/popups/comparison-not-added/" },
                        mainClass: "mfp-zoom-out",
                        removalDelay: 500,
                        closeOnBgClick: false
                    });
                }
            }
        }
        else {
            if (cookies.get(cookiename).length > 6) {
                var cookieRemoveVal = cookies.get(cookiename).replace(capID, "");
                cookies.set(cookiename, cookieRemoveVal, 30);

                var newRemoveCookie = cookieRemoveVal.replace(",,", ",").replace(/^,+/, "").replace(/,+$/, "");

                var cookieLengthRemove = newRemoveCookie.split(',').filter(Boolean).length;

                if (cookieLengthRemove > 0) {
                    $('#header .comparison-count').html(cookieLengthRemove);
                } else {
                    $('#header .comparison-count').html("");
                }
                if (cookieLengthRemove === 1) {
                    $('#compareLink').text("My Showroom");
                } else {
                    $('#compareLink').text("My Showroom");
                }
            } else {
                cookies.set(cookiename, "", -1);
                $('#header .comparison-count').html("");
                $('#compareLink').text("My Showroom");
            }

            $this.removeClass('remove');
            $this.addClass('add');
            $this.attr('data-status', 'add');
            $this.find('.js-comparrison-tool-text').html('Add to My Showroom');
            $this.find('path').attr('fill', '#9d9d9d');
            $this.find('input').attr('checked', false);
        }
    });

    $(document).on('click', '.js-back-to-top', function (e) {
        e.preventDefault();
        $("html, body").animate({ scrollTop: 0 });
    });

    // Vehicle detail page this is overwritten by css on mobile due to it having a separate show/hide scroll point
    $(window).scroll(function () {
        if ($(document).scrollTop() >= 375)
            $('#back-top-to-link').fadeIn();
        else
            $('#back-top-to-link').fadeOut();
    });

    ga(function(tracker) {
        var clientID = tracker.get('clientId');
        $("#form_analytics_id").val(clientID);
    });

    $(".menu-links").on("click", function (e) {
        e.preventDefault();
        $(this).parent().children(".sub-menu").slideToggle(500)
    });

    $(document).on('click', '.js-scroll-to-vehicle-brand', function (e) {
        var target = $(this).attr('href');

        $("html, body").animate({
            scrollTop: $(target).offset().top - $('#header').height() - 20
        }, 600);

    });

    // sticks mobile filter to header on vehicle model page audi/a1
    if ($('.secondary-filter-bar-sort-by-mobile').length > 0 && window.site.uptoSmall()) {
        $headerHeight = $('#header').height();
        var fixmeTop = $('.filter-filter').offset().top;

        $(document).on('scroll', function () {
            var currentScroll = $(document).scrollTop();
            if (currentScroll >= fixmeTop) {
                $('.header-filters').show();
                $('.filter-filter').hide();
            } else {
                $('.header-filters').hide();
                $('.filter-filter').show();
            }
            //$('.mobile-specification-filter .js-popout').css('display', 'none');
            //$('.popout.js-popout.mod-popout-filter.js-popout-filter-last').css('display', 'none');
            //$('.popout.js-popout.mod-popout-filter.js-popout-filter-last').hide();
        });
    }


    $(window).scroll(function () {
        if ($(window).scrollTop() >= 300) {
            $('#header').addClass('header-fixed');
        }
        else {
            $('#header').removeClass('header-fixed');
        }
    });
    
    if (window.site.uptoSmall()) {
        // Hide Header on on scroll down
        var lastScrollTop = 50;
        var delta = 300;
        var deltaNew = 50;

        $(document).on('scroll', function (e) {
            didScroll = true;
            hasScrolled();
            didScroll = false;
        });
    }

    function hasScrolled() {
        var st = $(document).scrollTop();
        // Make sure they scroll more than delta
        //
        if (st > lastScrollTop) {
            if (Math.abs(lastScrollTop - st) <= delta)
                return;
        } else {
            if (Math.abs(lastScrollTop - st) <= deltaNew)
                return;
        }
        if (st > lastScrollTop && st > delta) {
            // Scroll Down
            $('.header,.filter-fixed').css({ 'opacity': '0', 'display': 'none' });
        } else {
            // Scroll Up
            if (st + $(window).height() < $(document).height()) {
                //$('.secondary-filter-bar-sort-by-mobile').css('opacity','1');
                $('.header,.filter-fixed').css({ 'opacity': '1', 'display': 'block' });
            }
        }

        lastScrollTop = st;
    }

    // changes arrow state on accordion change
    //$('.js-toggle-header-popover').click(function(){
    //    $(this).next('.icon-chevron-down').toggleClass("icon-chevron-up");
    //});

    $('.quick-model-search').click(function () {
        $(this).toggleClass("quick-model-search-arrow-icon");
    });

    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
        $('html').addClass('is-ios');
    }

})(document, window);

$(function () {
    $.fn.equalize = function (measureID, apply) {
        apply = (apply === '') ? measureID : apply;
        var _containers = [];
        $(measureID).each(function () {
            var _containers = [];
            $(apply, this).each(function () {
                $(this).css('height', 'auto');
                _containers.push($(this).outerHeight());
            });
            var max = Math.max.apply(null, _containers);
            $(apply, this).each(function () {
                $(this).css('height', max + 'px');
            });
        });

        return this;
    };

    setTimeout(function () { $(document).equalize(".js-equal-heights", ".js-equal-unit"); }, 300);
});

$('.cookie-notification__close').click(function (e) {
  cookies.set('accept-cookies', true)
  $('.cookie-notification').addClass('-is-hidden')
})

$(document).ready(function () {
  if (!cookies.get("accept-cookies")) {
    $('.cookie-notification').removeClass('-is-hidden')
  }
});